// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';

const mainColor = '#333333';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${mainColor};
  padding-left: 24px;
`;

const Title = styled.h4`
  font-weight: bold;

  @media (max-width: 1092px) {
    color: #8070fd;
  }
`;

const Text = styled.div`
  font-size: 0.82rem;

  @media (max-width: 1092px) {
    font-size: 0.9rem;
  }
`;

const IconContainer = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c5c7d0;
  border-radius: 31px;
  height: 62px;
`;

function ValidationItem({Icon, title, text}) {
  const {t} = useTranslation();

  return (
    <Container>
      <IconContainer className="hide-on-mobile">
        <Icon sx={{fontSize: 40}} />
      </IconContainer>
      <TextContainer>
        <Title>{t(title)}</Title>
        <Text>{t(text)}</Text>
      </TextContainer>
    </Container>
  );
}

export default ValidationItem;
